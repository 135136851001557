<template>
  <section>
    <base-loading v-if="isTranslationLoading"></base-loading>
    <div v-else class="position-relative">
      <img class="img-bg" :src="changeBg()" />
      <!-- <img src="../../public/images/rMob.jpg" /> -->
      <base-info-page
        style="
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          padding-top: 5rem !important;
        "
      >
        <template #title
          >{{ translations.registration.registration }}
        </template>
        <template #default>
          <div v-if="currentStep === -1">
            <b>
              {{
                translations.registration.restore_account ??
                "Enter your email to proceed with account activation"
              }}
            </b>
          </div>
          <div v-if="currentStep === 0">
            <b>{{ translations.registration.Welcome_to }} {{ skinName }}</b>
          </div>
          <div v-if="currentStep === 1">
            <b>{{ translations.registration.Email_confirmation_code }}:</b>
          </div>
          <div v-if="currentStep === 2">
            <b>{{ translations.registration.Enter_data }}:</b>
          </div>

          <!-- <div v-if="currentStep === 3">
          <b>{{ translations.registration.Mobile_confirmation_code }}:</b>
        </div> -->
          <!-- <form @submit.prevent=""> ORIGINALE-->
          <form
            id="form-no-paste"
            :action="`/${this.$route.params.lang}/registration`"
            @submit.prevent=""
            autocomplete="off"
          >
            <!-- STEP 1A // COUNTRY AND CURRENCY SELECTION -->
            <transition name="fade">
              <div id="regStep0" v-if="currentStep === -1">
                <div class="col-12 my-3">
                  <input
                    type="text"
                    oninput="this.value = this.value.replace(/\s/g, '');"
                    :placeholder="translations.registration.email ?? 'Email'"
                    class="form-control mb-2"
                    v-model="email_restore"
                  />
                  <base-button
                    @click="restore"
                    color="grey"
                    nomargin="true"
                    :caption="translations.buttons.Confirm ?? 'Confirm'"
                  ></base-button>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div id="regStep0" v-if="currentStep === 0">
                <!-- <img src="../assets/img/rDesk.jpg" /> -->
                <div class="form-group row">
                  <div class="col">
                    <select
                      @change="getCountryPromos"
                      class="form-control my-3"
                      name="country"
                      id="country"
                      v-model="country"
                    >
                      <option value="null" disabled selected>
                        {{ translations.registration.country }}
                      </option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                        :value="country.code"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>

                  <div class="col">
                    <select
                      class="form-control my-3"
                      name="currency"
                      id="currency"
                      @change="getCountryPromos"
                      v-model="currency"
                    >
                      <option value="null" disabled selected>
                        {{ translations.registration.currency }}
                      </option>
                      <option
                        v-for="currency in currencies"
                        :key="currency.id"
                        :value="currency.id"
                      >
                        {{ currency.name }}
                      </option>
                    </select>
                  </div>
                  <div v-if="currency == 42" class="warning-text-for-czk">
                    <small class="alert-czk">
                      {{
                        translations?.registration?.alert_czk_currency ??
                        'If you choose CZK as your currency, you must upload a copy of your identity Card and input your Personal number in our "Documents" page after completing the registration process'
                      }}
                    </small>
                  </div>
                </div>
              </div>
            </transition>
            <!-- STEP 1B // EMAIL AND PASSWORD FORM -->
            <transition name="fade">
              <div id="regStep1" v-if="isStep1Enabled && currentStep === 0">
                <div class="form-group mt-2">
                  <div>
                    <b
                      >{{
                        translations.registration.Welcome_bouns_is_waiting
                      }}:</b
                    >
                  </div>
                  <select
                    class="form-control my-3"
                    name="campaign"
                    id="campaign"
                    v-model="campaign"
                    @change="getCampaign"
                  >
                    <option value="null" disabled>
                      {{ translations.registration.Please_select }}
                    </option>
                    <option value="none">
                      {{ translations.registration.No_promo }}
                    </option>
                    <option
                      v-for="campaign in filteredCampaigns"
                      :key="campaign?.id"
                      :value="campaign?.code"
                      :data-img="campaign?.media_url"
                      :data-sms="campaign?.options?.sms_verified ? 1 : 0"
                      :data-title="campaign?.name"
                    >
                      {{ campaign?.name }}
                    </option>
                    <option value="bonuscode">
                      {{
                        translations.registration.enter_code ??
                        "Enter Bonus Code"
                      }}
                    </option>
                  </select>
                  <div class="col-12 my-3" v-if="mandatorySms == true">
                    {{
                      translations.promotion.sms_verified_registration ??
                      "To use this promo, please verify your phone number in the next step or in your user area."
                    }}
                  </div>

                  <div class="col-12 my-3" v-if="showbonuscode">
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <input
                        :disabled="isCodeVerified"
                        type="text"
                        oninput="this.value = this.value.replace(/\s/g, '');"
                        :placeholder="
                          translations.registration.bonus_code ?? 'Bonus code'
                        "
                        class="form-control mr-2"
                        v-model="bonus_code"
                        :style="{ width: isCodeVerified ? '85%' : '50%' }"
                      />
                      <button
                        v-if="!isCodeVerified"
                        :disabled="isButtonDisabled || !bonus_code"
                        class="btn btn-verified-code"
                        :style="{
                          width: '45%',
                        }"
                        @click="verifyBonusCode()"
                      >
                        {{
                          translations?.registration?.verify_affiliate_code ??
                          "Verify code"
                        }}
                      </button>
                      <img
                        title="Verified"
                        class="verified-code"
                        v-if="isCodeVerified"
                        src="../../public/images/verified.svg"
                        alt="Verified"
                      />
                    </div>
                    <div
                      class="d-flex flex-column justify-content-start align-items-start w-100"
                    >
                      <small v-if="!bonusCodeNotValid && !isCodeVerified"
                        >{{
                          translations.registration.enter_code_help ??
                          "Enter the promotional code"
                        }}
                      </small>
                      <small
                        class="text-danger"
                        v-else-if="bonusCodeNotValid && !isCodeVerified"
                        >{{
                          translations.registration.code_not_valid ??
                          "Promotional code not valid"
                        }}
                      </small>
                      <div
                        v-if="
                          bonusCodeResult &&
                          bonusCodeResult.medias &&
                          bonusCodeResult.medias.length > 0
                        "
                        class="position-relative mt-3"
                      >
                        <div
                          class="overlayImgCode p-3"
                          v-if="bonusCodeResult?.action?.name"
                        >
                          <b style="color: white">{{
                            bonusCodeResult?.action?.name
                          }}</b>
                        </div>
                        <img
                          style="pointer-events: none !important"
                          class="img-fluid"
                          :src="bonusCodeResult.medias[0].url_banner"
                          alt="Promotion Banner"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="campaignImage != ''"
                  class="form-img-campaign form-group my-3"
                >
                  <img class="w-100" :src="campaignImage" alt="Campaign" />
                  <div class="overlayImg p-3" v-if="campaignTitle != ''">
                    <b>{{ this.campaignTitle }}</b>
                  </div>
                </div>
                <div class="form-group mt-4">
                  <div>
                    <b>{{ translations.registration.Enter_data }}:</b>
                  </div>
                  <input
                    @keyup="checkNickname"
                    @keydown.space="spaceNotAllowed"
                    class="form-control my-3"
                    type="text"
                    :placeholder="translations.myprofile.Nickname"
                    id="nickname"
                    name="nickname"
                    v-model.trim="nickname"
                  />
                  <transition name="error-message">
                    <p v-if="!!nicknameErrorMessage" class="warning">
                      {{ nicknameErrorMessage }}
                    </p>
                  </transition>
                  <input
                    @keyup="checkEmail"
                    @keydown.space="spaceNotAllowed"
                    class="form-control my-3"
                    type="email"
                    :placeholder="translations.myprofile.Email"
                    id="email"
                    name="email"
                    v-model.trim="email"
                  />
                  <transition name="error-message">
                    <p v-if="!!emailErrorMessage" class="warning">
                      {{ emailErrorMessage }}
                    </p>
                  </transition>
                  <input
                    @keyup="checkPassword"
                    @keydown.space="spaceNotAllowed"
                    class="form-control my-3"
                    type="password"
                    name="password"
                    id="password"
                    :placeholder="translations.login.password"
                    v-model="password"
                  />
                  <transition name="error-message">
                    <p v-if="!!passwordErrorMessage" class="warning">
                      {{ passwordErrorMessage }}
                    </p>
                  </transition>
                  <input
                    @keyup="passwordsMatching"
                    class="form-control my-3"
                    type="password"
                    name="repeat-password"
                    id="repeat-password"
                    :placeholder="translations.myprofile.Confirm_password"
                    v-model="confirmPassword"
                  />
                  <transition name="error-message">
                    <p v-if="confirmPasswordFieldError" class="warning">
                      {{ translations.registration.passwords_no_match }}
                    </p>
                  </transition>
                </div>
                <!-- HERE -->
                <div class="form-group">
                  <div class="row">
                    <div class="col-3 col-sm-3">
                      <input
                        type="text"
                        class="form-control my-2"
                        name="phonePrefix"
                        id="phonePrefix"
                        v-model.trim="phonePrefix"
                        @input="resetValidityPhoneNumber"
                      />
                      <transition name="error-message">
                        <p v-if="isPrefixInvalid" class="warning my-2">
                          {{ prefixErrorMessage }}
                        </p>
                      </transition>
                    </div>
                    <div class="col-9 col-sm-9">
                      <input
                        type="tel"
                        class="form-control my-2"
                        :placeholder="translations.registration.phone"
                        name="phone"
                        id="phone"
                        v-model.trim="phoneNumber"
                        @input="resetValidityPhoneNumber"
                        @keyup="checkValidityNumberFormat"
                      />
                      <transition name="error-message">
                        <p v-if="isMobileInvalid" class="warning my-2">
                          {{ mobileErrorMessage }}
                        </p>
                      </transition>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <transition name="info-message-pn">
                    <p class="info-message-pn my-2">
                      {{
                        translations.registration
                          .multiplie_error_on_phone_number ??
                        " In case you get a repeated error on the telephone number you can leave the field blank and enter it later, and proceed with registration."
                      }}
                    </p>
                  </transition>
                </div>
              </div>
            </transition>
            <!-- STEP 2 // EMAIL VERIFICATION CODE -->
            <transition name="fade">
              <div id="regStep2" v-if="currentStep === 1">
                <p class="my-1">
                  {{ translations.registration.Email_Address }}:
                  <i>{{ email }}</i>
                </p>
                <div class="form-group">
                  <div class="row d-flex align-items-center">
                    <div class="col-6 col-sm-7">
                      <input
                        type="text"
                        name="confirmationCode"
                        id="confirmationCode"
                        :disabled="codeMailCorrect"
                        :placeholder="translations.registration.Code"
                        class="form-control my-3"
                        v-model="verificationCodeMail"
                      />
                    </div>
                    <div
                      class="col-6 col-sm-5"
                      id="send-mail-code-again-btn"
                      v-if="!codeMailCorrect"
                    >
                      <base-button
                        @click="sendCodeAgain"
                        color="grey"
                        :timerButton="true"
                        :class="disabledClassMail"
                        :timer="timerMail"
                        nomargin="true"
                        :caption="translations.registration.resend_code"
                      ></base-button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div
                class="mt-2"
                id="regStep4"
                v-if="currentStep === 1 && phoneNumber.length > 0"
              >
                <p class="my-1">
                  {{ translations.registration.phone }}:
                  <i>{{ "(" + phonePrefix + ") " + phoneNumber }}</i>
                </p>
                <div class="form-group">
                  <div class="row d-flex align-items-center">
                    <div class="col-6 col-sm-7">
                      <input
                        type="text"
                        name="confirmationCode"
                        id="confirmationCode"
                        :disabled="codeSmsCorrect"
                        :placeholder="translations.registration.Code"
                        class="form-control my-3"
                        v-model="verificationCodePhone"
                      />
                    </div>
                    <div
                      class="col-6 col-sm-5"
                      id="send-sms-again-btn"
                      v-if="!codeSmsCorrect"
                    >
                      <base-button
                        @click="sendSMSAgain"
                        color="grey"
                        :timerButton="true"
                        :class="disabledClassSms"
                        :timer="timerSms"
                        nomargin="true"
                        :caption="translations.registration.resend_code"
                      ></base-button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <!-- STEP 3 // COMPLETE REGISTRATION -->
            <transition name="fade">
              <div id="regStep3" v-if="currentStep === 2">
                <div class="text-error-small">
                  {{ translations.registration.Fields_required }}
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control my-3"
                    :placeholder="translations.registration.first_name + '*'"
                    name="firstName"
                    id="firstName"
                    v-model.trim="firstName"
                    @keyup="checkFields('firstName')"
                  />
                  <transition name="error-message">
                    <p v-if="isFieldsInvalid" class="warning my-2">
                      {{ fieldsErrorMessage }}
                    </p>
                  </transition>
                  <p class="warning" v-if="specialCharMessages.firstName">
                    {{ specialCharMessages.firstName }}
                  </p>
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control my-3"
                    :placeholder="translations.registration.last_name + '*'"
                    name="lastName"
                    id="lastName"
                    v-model.trim="lastName"
                    @keyup="checkFields('lastName')"
                  />
                  <transition name="error-message">
                    <p v-if="isFieldsInvalid" class="warning my-2">
                      {{ fieldsErrorMessage }}
                    </p>
                  </transition>
                  <p class="warning" v-if="specialCharMessages.lastName">
                    {{ specialCharMessages.lastName }}
                  </p>
                </div>

                <div class="form-group">
                  <select
                    class="form-control my-3"
                    name="gender"
                    id="gender"
                    v-model="gender"
                  >
                    <option value="" disabled selected>
                      {{ translations.myprofile.Gender + "*" }}
                    </option>
                    <option value="M">
                      {{ translations.registration.Male }}
                    </option>
                    <option value="F">
                      {{ translations.registration.Female }}
                    </option>
                    <option value="GN">
                      {{ translations.registration.Other }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control my-3"
                    :placeholder="translations.registration.street + '*'"
                    name="street"
                    id="street"
                    v-model.trim="street"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control my-3"
                    :placeholder="translations.registration.street_number + '*'"
                    name="streetNumber"
                    id="streetNumber"
                    v-model.trim="streetNumber"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control my-3"
                    :placeholder="translations.registration.city + '*'"
                    name="city"
                    id="city"
                    v-model.trim="city"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control my-3"
                    :placeholder="translations.registration.postal_code + '*'"
                    name="postalCode"
                    id="postalCode"
                    v-model.trim="postalCode"
                  />
                </div>

                <div class="form-group mt-5">
                  <div class="col">
                    <p>
                      <b
                        >{{
                          translations.registration.birth_country ??
                          "Birth country"
                        }}*</b
                      >
                    </p>
                    <select
                      class="form-control my-3"
                      name="birth_country"
                      id="birth_country"
                      v-model="birth_country"
                    >
                      <option value="null" disabled selected>
                        {{
                          translations.registration.birth_country ??
                          "Birth country"
                        }}
                      </option>
                      <option
                        v-for="country in countriesAll"
                        :key="country.id"
                        :value="country.code"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <p>
                    <b>{{ translations.registration.dob + "*" }}</b>
                  </p>
                  <div class="row">
                    <div class="col">
                      <select
                        @change="checkPlayerDOB"
                        name="day"
                        id="day"
                        class="form-control my-3"
                        v-model="dob_D"
                      >
                        <option value="" selected disabled>
                          {{ translations.myprofile.Day }}
                        </option>
                        <option
                          v-for="day in days"
                          :key="day.value"
                          :value="day.value"
                        >
                          {{ day.number }}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <select
                        @change="checkPlayerDOB"
                        name="month"
                        id="month"
                        class="form-control my-3"
                        v-model="dob_M"
                      >
                        <option value="" selected disabled>
                          {{ translations.myprofile.Month }}
                        </option>
                        <option
                          v-for="month in months"
                          :key="month.value"
                          :value="month.value"
                        >
                          {{ month.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <select
                        @change="checkPlayerDOB"
                        name="year"
                        id="year"
                        class="form-control my-3"
                        v-model="dob_Y"
                      >
                        <option value="" selected disabled>
                          {{ translations.myprofile.Year }}
                        </option>
                        <option
                          v-for="year in years"
                          :key="year.value"
                          :value="year.value"
                        >
                          {{ year.number }}
                        </option>
                      </select>
                    </div>
                    <transition name="error-message">
                      <p v-if="dobFieldError" class="warning">
                        {{ dob_error }}
                      </p>
                    </transition>
                  </div>
                  <div class="row">
                    <p>
                      {{ translations.registration.terms_and_conditions ?? "" }}
                    </p>
                  </div>
                  <div class="form-group">
                    <div class="form-check my-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="termsAndConditions"
                        v-model="isTermsChecked"
                      />
                      <label class="form-check-label" for="termsAndConditions"
                        >{{ translations.registration.I_agree_general }}
                        <a
                          :href="`/${lang}/terms-and-conditions`"
                          target="_blank"
                        >
                          {{ translations.footer.terms_condition + "*" }} </a
                        >.
                      </label>
                    </div>
                    <div class="form-check my-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="newsletter"
                        v-model="isNewsletterChecked"
                      />
                      <label class="form-check-label" for="newsletter"
                        >{{ translations.registration.Accept_promotion }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group mt-5">
                    <div class="form-check">
                      <recaptcha-button
                        @clicked="setCaptcha"
                        :siteKey="captcha.key"
                      ></recaptcha-button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <!-- BUTTONS -->
            <!-- BUTTTON NEXT -->
            <transition name="fade">
              <div class="container" v-if="currentStep === 0">
                <div class="row d-flex justify-content-between">
                  <base-button
                    v-if="!isResponseLoading"
                    @click="checkFirstStepFields"
                    color="grey"
                    :caption="translations.buttons.next"
                  ></base-button>
                  <div
                    v-else
                    className="d-flex justify-content-center align-items-center w-100 h-100 loader-response-container"
                  >
                    <span className="loader-response"></span>
                  </div>
                </div>
              </div>
            </transition>
            <!-- BUTTON VERIFICATION EMAIL -->
            <transition name="fade">
              <div class="container" v-if="currentStep === 1">
                <div class="row d-flex justify-content-between my-2">
                  <base-button
                    style="flex: 1 !important"
                    :disabled="!isVerificationCodeMail"
                    @click="contactVerification('email')"
                    class="mx-1"
                    :color="!codeMailCorrect ? 'grey' : 'green'"
                    :caption="
                      !codeMailCorrect
                        ? translations.buttons.Confirm_code_mail ??
                          'Verify e-mail'
                        : translations.buttons.mailVerified ?? 'E-mail verified'
                    "
                  ></base-button>
                  <base-button
                    v-if="phoneNumber.length > 0"
                    style="flex: 1 !important"
                    :disabled="!isVerificationCodePhone"
                    @click="contactVerification('sms')"
                    :color="!codeSmsCorrect ? 'grey' : 'green'"
                    class="mx-1"
                    :caption="
                      !codeSmsCorrect
                        ? translations.buttons.Confirm_code_phone ??
                          'Verify number'
                        : translations.buttons.smsNumberVerified ??
                          'Phone verified'
                    "
                  ></base-button>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="container" v-if="currentStep === 1">
                <div class="row d-flex justify-content-center my-2">
                  <base-button
                    style="width: 100% !important"
                    @click="goToLastStep()"
                    color="grey"
                    :disabled="!codeMailCorrect && !codeSmsCorrect"
                    :caption="translations.buttons.next"
                  ></base-button>
                </div>
              </div>
            </transition>
            <!-- BUTTON SAVE -->
            <transition name="fade">
              <div class="container" v-if="currentStep === 2">
                <div class="row d-flex justify-content-between">
                  <base-button
                    :disabled="!dataIsComplete"
                    @click="saveRegistration"
                    color="grey"
                    :caption="translations.buttons.register"
                  ></base-button>
                </div>
              </div>
            </transition>
            <!-- BUTTON VERIFICATION SMS -->
            <!-- <transition name="fade">
            <div class="container" v-if="currentStep === 1">
              <div class="row d-flex justify-content-between">
                <base-button
                  :disabled="!isVerificationCode"
                  @click="contactVerification('sms')"
                  color="grey"
                  :caption="translations.buttons.Confirm_code"
                ></base-button>
              </div>
            </div>
          </transition> -->
          </form>
        </template>
      </base-info-page>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import RecaptchaButton from "@/components/global/ui/RecaptchaButton.vue";
export default {
  components: {
    RecaptchaButton,
  },
  data() {
    return {
      bgForm: "../assets/img/rMob.jpg",
      opacity: 30,
      isCodeVerified: false,
      isButtonDisabled: true,
      isResponseLoading: false,
      errorRegister: false,
      messageCopy: "",
      currentStep: 0,
      token: null,
      // STEP 1
      currency: "null",
      country: "null",
      campaign: "null",
      campaignImage: "",
      campaignTitle: "",
      nickname: "",
      email: "",
      bonus_code: "",
      showbonuscode: false,
      password: "",
      confirmPassword: "",
      specialCharMessages: {
        firstName: "",
        lastName: "",
      },
      // STEP 2
      timer: 60,
      timerMail: 60,
      timerSms: 60,
      verificationCodePhone: "",
      verificationCodeMail: "",
      // STEP 3
      firstName: "",
      lastName: "",
      gender: "",
      street: "",
      streetNumber: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      phonePrefix: null,
      isPrefixInvalid: false,
      isPEP: false,
      isUBO: false,
      isTermsChecked: false,
      isNewsletterChecked: false,
      isCaptchaChecked: false,
      birth_country: "",
      dob_D: "",
      dob_M: "",
      dob_Y: "",
      email_restore: "",
      mandatorySms: false,
      dobFieldError: false,
      dob_error: null,
      isPlayerAdult: false,
      referralCode: "",
      lang: localStorage.getItem("language"),
      isClickedButtonToVerifyMobile: false,
      isRegistrationSaving: false,
    };
  },
  watch: {
    bonus_code(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isButtonDisabled = false;
      } else {
        this.isButtonDisabled = true;
      }
    },
    isFirstStepComplete() {},
    verificationConfirmed(value) {
      if (value) {
        // console.log("VALUE WATCHER VERIFICATION CONFIRMED", value);
        // this.nextStep();
      }
    },
    // isSMSCalled(value, oldValue) {
    //   if (value != oldValue && value) {
    //     // this.nextStep();
    //     this.countdownSmsCode();
    //   }
    // },
    phonePrefix(value) {
      const regex = /^\+\d+$/;
      this.$store.commit("registration/setMobileError", null);
      if (regex.test(value)) {
        this.isPrefixInvalid = false;
      } else {
        this.isPrefixInvalid = true;
      }
    },
    /* eslint-disable */
    phoneNumber(value) {
      this.$store.commit("registration/setMobileError", null);
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");
      // console.log("scrivo", value);
      // console.log("valore di errorMessage", this.mobileErrorMessage);
      // console.log("---------------");
    },
    /* eslint-disable */
  },
  computed: {
    isStep1Enabled() {
      return this.currency != "null" && this.country != "null";
    },
    captcha() {
      return this.$store.getters["skinSettings/googleCaptcha"];
    },
    disabledClassSms() {
      if (this.timerSms != 0) {
        return "disabled";
      } else {
        return "";
      }
    },
    disabledClassMail() {
      if (this.timerMail != 0) {
        return "disabled";
      } else {
        return "";
      }
    },
    isVerificationCodePhone() {
      return this.verificationCodePhone.length > 2;
    },
    isVerificationCodeMail() {
      return this.verificationCodeMail.length > 2;
    },
    dataIsComplete() {
      return (
        !this.isFirstStepComplete &&
        !!this.firstName &&
        !!this.lastName &&
        !!this.gender &&
        !!this.street &&
        !!this.streetNumber &&
        !!this.city &&
        !!this.postalCode &&
        !this.isFieldsInvalid &&
        !!this.isPlayerAdult &&
        !!this.isTermsChecked &&
        !!this.isCaptchaChecked &&
        !this.isPrefixInvalid
      );
    },
    prefixErrorMessage() {
      return this.translations.registration.follow_this_example + ": +44";
    },
    filteredCampaigns() {
      return this.campaigns.filter(
        (campaign) => campaign.categories.length === 0
      );
    },
    ...mapGetters("skinSettings", [
      "countries",
      "countriesAll",
      "currencies",
      "campaigns",
      "skinName",
      "days",
      "months",
      "years",
      "skinId",
    ]),
    ...mapGetters("registration", [
      "isNicknameInvalid",
      "nicknameErrorMessage",
      "isEmailInvalid",
      "emailErrorMessage",
      "isPasswordInvalid",
      "passwordErrorMessage",
      "confirmPasswordFieldError",
      "passwordMatchControl",
      "verificationPlayerID",
      "isFirstStepComplete",
      "verificationConfirmed",
      "isMobileInvalid",
      "mobileErrorMessage",
      "isFieldsInvalid",
      "fieldsErrorMessage",
      "isSMSCalled",
      "codeSmsCorrect",
      "codeMailCorrect",
      "sendVerificationIsOkay",
    ]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("skinPromotions", ["bonusCodeResult", "bonusCodeNotValid"]),
  },
  methods: {
   checkValidityNumberFormat() {
      this.phoneNumber = this.phoneNumber.replace(/^0+/, "");
    },
    verifyBonusCode() {
      let body = {
        code: this.bonus_code,
        currency_id: this.currency,
      };
      // console.log(body);
      this.$store.dispatch("skinPromotions/verifyCode", { body }).then(() => {
        if (!this.bonusCodeNotValid) {
          // console.log("ss");
          this.isCodeVerified = true;
        }
      });
    },
    changeBg() {
      const width = window.innerWidth;
      const isMobile = width <= 567;
      const opacity100 = this.isStep1Enabled ? false : true;

      if (isMobile) {
        this.bgForm = opacity100
          ? "../../images/rMob.jpg"
          : "../../images/rMob30.jpg";
      } else {
        this.bgForm = opacity100
          ? "../../images/rDesk.jpg"
          : "../../images/rDesk30.jpg";
      }
      return `${this.bgForm}`;
    },
    changeOpacity(value) {
      if (value !== this.opacity) this.opacity = value;

      const opacity100 = document.getElementById("opacity100");
      const opacity30 = document.getElementById("opacity30");

      if (this.opacity == 100) {
        opacity100.checked = true;
        opacity30.checked = false;
      } else {
        opacity100.checked = false;
        opacity30.checked = true;
      }
    },
    async sendEmailAndPhoneVerification() {
      await this.sendVerification().then(() => {
        if (!this.errorRegister) {
          this.sendSMSAgain();
        } else {
          return;
        }
      });
    },
    resetValidityPhoneNumber() {
      this.$store.commit("registration/setPhoneNumberInvalid", true);
    },
    preventCopy() {
      const pasteBox = document.getElementById("form-no-paste");
      if (pasteBox) {
        pasteBox.onpaste = () => {
          alert(this.messageCopy);
          return false;
        };
      }
    },
    setCaptcha(value) {
      this.isCaptchaChecked = value;
    },
    nextStep() {
      this.isResponseLoading = false;
      this.currentStep++;
    },
    spaceNotAllowed() {
      const payload = {
        message: this.translations.registration.space_not_allowed,
        status: "error",
      };
      this.$store.commit("alert/topRightNotification", payload, {
        root: true,
      });
    },

    getAndStoreReferralCode() {
      const doesReferralExist = VueCookies.get("btag");
      if (doesReferralExist) {
        this.referralCode = VueCookies.get("btag").replace(/,/g, "");
      } else {
        this.referralCode = "";
      }
    },

    async getCampaign(event) {
      this.showbonuscode = false;
      this.bonus_code = null;
      this.isCodeVerified = false;
      this.$store.commit("skinPromotions/setInfoBonus", null);
      this.$store.commit("skinPromotions/setIsBonusCodeNotValid", false);
      let img =
        event.target.options[event.target.options.selectedIndex].getAttribute(
          "data-img"
        );
      this.campaignTitle =
        event.target.options[event.target.options.selectedIndex].getAttribute(
          "data-title"
        );
      let sms =
        event.target.options[event.target.options.selectedIndex].getAttribute(
          "data-sms"
        );
      if (sms == 1) this.mandatorySms = true;
      else this.mandatorySms = false;

      if (img != null) this.campaignImage = img;
      else this.campaignImage = "";

      if (event.target.value == "bonuscode") this.showbonuscode = true;
      else {
        this.showbonuscode = false;
        this.bonus_code = null;
        this.$store.commit("skinPromotions/setInfoBonus", null);
        this.$store.commit("skinPromotions/setIsBonusCodeNotValid", false);
      }
    },
    async getCountryPromos() {
      const selectedCountry = this.country;
      const phonePrefix = this.countries.find(
        (country) => country.code == selectedCountry
      );
      this.phonePrefix = phonePrefix.phone_prefix;
      this.$store
        .dispatch("skinSettings/callCampaigns", {
          country: this.country,
          currency: this.currency,
        })
        .then(() => {
          if (0 in this.filteredCampaigns) {
            let codeWelcome = this.filteredCampaigns[0].code;
            this.filteredCampaigns = codeWelcome;

            if (this.filteredCampaigns[0].media_url != null)
              this.campaignImage = this.filteredCampaigns[0].media_url;
            else this.campaignImage = "";

            this.campaignTitle = this.filteredCampaigns[0].name;
          } else {
            this.campaign = "null";
            this.campaignImage = "";
            this.campaignTitle = "";
          }

          let promocode = this.$route.query.promocode;
          if (promocode) {
            this.campaign = "bonuscode";
            this.showbonuscode = true;
            this.bonus_code = promocode;
            this.campaignImage = "";
            this.campaignTitle = "";
          }
        });
    },

    checkNickname() {
      this.nickname = this.removeCharSpecial(this.nickname, "nickname");
      // removeCharSpecial(this.nickname)
      this.$store.dispatch("registration/checkNickname", this.nickname);
    },

    checkEmail() {
      this.$store.dispatch("registration/checkEmail", this.email);
    },
    checkPassword() {
      this.$store.dispatch("registration/setPasswordStatus", this.password);
      if (this.confirmPassword.length > 5) {
        this.passwordsMatching();
      }
    },
    passwordsMatching() {
      const pass = {
        password: this.password,
        confirm: this.confirmPassword,
      };
      this.$store.commit("registration/setPasswordMatchingStatus", pass);
    },

    goToLastStep() {
      if (this.codeMailCorrect || this.codeSmsCorrect) {
        this.nextStep();
      } else {
        return;
      }
    },

    async sendVerification() {
      let emailBeforeAt = this.email
        .substring(0, this.email.indexOf("@"))
        .toLowerCase();
      let passwordToLower = this.password.toLowerCase();
      let nicknameToLower = this.nickname.toLowerCase();

      if (
        passwordToLower == emailBeforeAt ||
        passwordToLower == nicknameToLower
      ) {
        alert(this.translations.registration.password_nickname_email_check);
        return;
      }
      let promocode = this.campaign;
      if (
        this.showbonuscode &&
        this.bonus_code != "" &&
        this.bonus_code != null
      )
        promocode = this.bonus_code;

      let base_url = window.location.origin;
      if (
        base_url.includes("localhost") ||
        base_url.includes("kajot-casino-prime")
      )
        base_url = "";

      const body = {
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
        acceptance: 1,
        country: this.country,
        currency_id: this.currency,
        promocode: promocode,
        name: this.nickname,
        username: this.nickname,
        nocheck: 1,
        domain: base_url,
        mobile: this.phonePrefix + this.phoneNumber,
        dob: "1970-01-01",
        force_smtp: 1,
        language: localStorage.getItem("language"),
      };

      try {
        this.isResponseLoading = true;
        await this.$store
          .dispatch("registration/sendVerificationCode", body)
          .then(() => {
            if (this.sendVerificationIsOkay == true) {
              this.errorRegister = false;
              this.nextStep();
              this.countDownMailCode();
            } else {
              alert("Something went wrong during registration");
              this.isResponseLoading = false;
              this.errorRegister = true;
            }
          });
      } catch (error) {
        this.isResponseLoading = false;
        this.errorRegister = true;
        alert("Something went wrong during registration");

        console.log(error);
        return;
      }
    },

    sendCodeAgainCountdown() {
      this.timer = 60;
      let countDown = setInterval(() => {
        this.timer--;
        if (this.timer == 0) {
          clearInterval(countDown);
        }
      }, 1000);
    },

    countdownSmsCode() {
      this.timerSms = 60;
      let countDownSms = setInterval(() => {
        this.timerSms--;
        if (this.timerSms == 0) {
          clearInterval(countDownSms);
        }
      }, 1000);
    },

    countDownMailCode() {
      // alert("avvio countdown mail");
      this.timerMail = 60;
      let countDown = setInterval(() => {
        this.timerMail--;
        if (this.timerMail == 0) {
          clearInterval(countDown);
        }
      }, 1000);
    },

    sendCodeAgain() {
      if (this.timerMail != 0) {
        return false;
      }
      // this.sendCodeAgainCountdown();
      this.countDownMailCode();
      this.$store.dispatch(
        "registration/sendVerificationCodeAgain",
        this.verificationPlayerID
      );
    },

    sendSMSAgain() {
      if (this.timerSms != 0) {
        return false;
      }
      // this.sendCodeAgainCountdown();
      this.countdownSmsCode();
      const playerId = this.verificationPlayerID;
      const skinId = this.skinId;
      if (mobile.length > 1) {
        this.$store.dispatch("registration/callTwilio", { playerId, skinId });
      }
    },

    sendSMSCode() {
      const playerId = this.verificationPlayerID;
      const skinId = this.skinId;
      let mobile = this.phoneNumber;
      if (mobile.length > 1) {
        this.$store
          .dispatch("registration/callTwilio", { playerId, skinId })
          .then(() => {
            this.countdownSmsCode();
          });
      }
    },

    async contactVerification(service) {
      if (
        (service == "email" && this.codeMailCorrect) ||
        (service == "sms" && this.codeSmsCorrect)
      ) {
        // console.log("SONO NEL REUTRN");
        return;
      } else {
        const verification =
          service == "sms"
            ? this.verificationCodePhone
            : this.verificationCodeMail;
        // let service = this.verificationCodePhone ? "sms" : "email";
        this.$store.dispatch("registration/contactVerification", {
          verification,
          service,
        });
      }
    },

    checkPlayerDOB() {
      this.dob_error = "";
      const birthDate =
        new Date(this.dob_Y, +this.dob_M - 1, this.dob_D).getMonth() ==
        this.dob_M - 1;
      if (!birthDate) {
        this.dobFieldError = true;
        this.isPlayerAdult = false;
        this.dob_error = this.translations.registration.dob_invalid;
        return;
      }
      if (this.dob_D != "" && this.dob_M != "" && this.dob_Y != "") {
        let playerDOB = Date.parse(`${this.dob_M}/${this.dob_D}/${this.dob_Y}`);
        let minimumDOB = Date.parse(
          moment().subtract(18, "years").toISOString()
        );
        if (playerDOB <= minimumDOB) {
          this.dobFieldError = false;
          this.isPlayerAdult = true;
          this.checkFields_playerDOB();
          return;
        } else {
          this.dob_error = this.translations.registration.dob_error;
          this.dobFieldError = true;
          this.isPlayerAdult = false;
          return;
        }
      }
    },

    async checkFirstStepFields() {
      const fields = [
        { id: "#email", value: this.email },
        { id: "#nickname", value: this.nickname },
        { id: "#password", value: this.password },
        { id: "#repeat-password", value: this.confirmPassword },
        //   { id: "#phonePrefix", value: this.phonePrefix },
        //   { id: "#phone", value: this.phoneNumber },
        { id: "#country", value: this.country },
        { id: "#currency", value: this.currency },
        { id: "#campaign", value: this.campaign },
      ];

      let hasEmptyFields = false;

      fields.forEach((field) => {
        const element = document.querySelector(field.id);
        if (field.value === "" || field.value === "null") {
          if (element) element?.classList?.add("error-field-empty");
          hasEmptyFields = true;
          setTimeout(() => {
            if (element) element?.classList?.remove("error-field-empty");
          }, 2000);
        }
      });

      if (hasEmptyFields) {
        alert(
          `${
            this.translations?.body?.mustFillAllFIelds ??
            "You must fill all fields"
          }`
        );
        return;
      } else {
        if (
          this.isEmailInvalid == false &&
          this.mobileErrorMessage == null &&
          this.isNicknameInvalid == false
        ) {
          this.nickname = this.removeCharSpecial(this.nickname, "nickname");
          this.checkPhoneNumber();
        }
        //  else {
        //   console.log("emailInvalid", this.isEmailInvalid);
        //   console.log("mobileErrormessage", this.mobileErrorMessage);
        //   console.log("nicknameInvalid", this.isNicknameInvalid);
        // }
      }
    },
    async sendValidPhone() {
      let mob = this.phoneNumber;
      if (mob.length < 1) return true;

      let verificationPhone = await this.$store.dispatch(
        "registration/validationPhone",
        {
          mobile: this.phonePrefix + this.phoneNumber,
        }
      );
      if (!verificationPhone) return false;

      return true;
    },
    async checkPhoneNumber() {
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");

      let mobile = this.phoneNumber;
      let prefix = this.phonePrefix;

      if (mobile.length < 1) {
        mobile = "";
        prefix = "";
      }

      await this.$store

        .dispatch("registration/checkPhoneNumberForRegistration", {
          mobile,
          prefix,
        })
        .then(() => {
          this.isClickedButtonToVerifyMobile = true;
          if (this.isMobileInvalid == 1) {
            return;
          } else {
            this.sendValidPhone().then((response) => {
              if (response) {
                this.sendVerification().then(() => {
                  if (!this.errorRegister) {
                    this.sendSMSCode();
                  } else {
                    this.errorRegister = true;
                    return;
                  }
                });
              } else {
                alert(
                  this.translations.registration.phoneNotValid ??
                    "Invalid telephone number; if you believe the telephone number exists, please contact customer service"
                );
                return;
              }
            });
          }
          setTimeout(() => {
            this.isClickedButtonToVerifyMobile = false;
          }, 2000);
        });
    },
    checkFields_playerDOB() {
      this.firstName = this.removeCharSpecial_checkPlayerDOB(this.firstName);
      this.lastName = this.removeCharSpecial_checkPlayerDOB(this.lastName);
      let fields = {
        firstname: this.firstName,
        lastname: this.lastName,
        dob: this.dob_Y + "-" + this.dob_M + "-" + this.dob_D,
      };
      let checkMga = { fields, emailVerified: true };
      this.$store.dispatch("registration/checkFields", { checkMga });
    },
    checkFields(fieldName) {
      this.specialCharMessages[fieldName] = "";

      // check if contain special char
      if (/[àèéìòùáéíóúâêîôûäëïöüñç.!?]/.test(this[fieldName])) {
        this.specialCharMessages[fieldName] =
          this.translations?.registration?.error_unexpected_special_char ??
          "Special characters dont'allowed.All special characters will be replaced with their normal variant. Example: (à -> a).";
        this[fieldName] = this.removeCharSpecial(this[fieldName], fieldName);
      }

      this.firstName = this.removeCharSpecial(this.firstName, "firstName");
      this.lastName = this.removeCharSpecial(this.lastName, "lastName");

      let fields = {
        firstname: this.firstName,
        lastname: this.lastName,
        dob: this.dob_Y + "-" + this.dob_M + "-" + this.dob_D,
      };

      // console.log(fields)
      let checkMga = { fields, emailVerified: true };
      this.$store.dispatch("registration/checkFields", { checkMga });
    },
    restore() {
      let email_restore = this.email_restore;
      if (!email_restore)
        return alert(
          this.translations.registration.email_error ??
            "The email you provide is not valid"
        );

      this.$store
        .dispatch("registration/restore", {
          email: email_restore,
          token: this.token,
        })
        .then((response) => {
          if (!response.is_valid) {
            return alert(
              this.translations.registration.token_not_valid ??
                "token expired or not valid or email not valid or email is not valid"
            );
          } else {
            let player = response.tokenable;
            if (
              player.email_verified_at != null &&
              player.firstname == "" &&
              player.lastname == ""
            ) {
              return alert(
                this.translations.registration.account_confirmed ??
                  "Email already Confirmed. If you have forgotten your password, proceed with recovery, or log in."
              );
            } else {
              let body = { email_verified_at: response.created_at };
              let playerId = player.user_id;
              this.$store
                .dispatch("registration/updatePlayer", {
                  body,
                  playerId,
                })
                .then(() => {
                  this.currentStep = 2;
                  this.phonePrefix = "+44";
                });
            }
          }
        });
    },
    removeCharSpecial_checkPlayerDOB(s) {
      let charAccents = {
        à: "a",
        À: "A",
        á: "a",
        Á: "A",
        â: "a",
        Â: "A",
        ä: "a",
        Ä: "A",
        è: "e",
        È: "E",
        é: "e",
        É: "E",
        ê: "e",
        Ê: "E",
        ë: "e",
        Ë: "E",
        í: "i",
        Í: "I",
        ì: "i",
        Ì: "I",
        î: "i",
        Î: "I",
        ï: "i",
        Ï: "I",
        ò: "o",
        Ò: "O",
        ó: "o",
        Ó: "O",
        ô: "o",
        Ô: "O",
        ö: "o",
        Ö: "O",
        ù: "u",
        Ù: "U",
        ú: "u",
        Ú: "U",
        û: "u",
        Û: "U",
        ü: "u",
        Ü: "U",
        ý: "y",
        Ý: "Y",
        ž: "z",
        Ž: "Z",
        ß: "ss",
        Ç: "C",
        č: "c",
        Č: "C",
        ď: "d",
        Ď: "D",
        ĺ: "l",
        Ĺ: "L",
        ľ: "l",
        Ľ: "L",
        ň: "n",
        Ň: "N",
        š: "s",
        Š: "S",
        ť: "t",
        Ť: "T",
        ŕ: "r",
        Ŕ: "R",
        "!": "",
        "?": "",
        ".": "",
      };

      // change characters
      s = s.replace(
        /[àÀáÁâÂäÄèÈéÉêÊëËíÍìÌîÎïÏòÒóÓôÔöÖùÙúÚûÛüÜýÝžŽßÇčČďĎĺĹľĽňŇšŠťŤŕŔ.!?]/g,
        (match) => charAccents[match] || match
      );

      // Rimuovi tutte le emoji
      s = s.replace(
        /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F1E6}-\u{1F1FF}]|[\u{200D}\u{200B}]/gu,
        ""
      );

      // console.log('qui')
      // remove special characters
      s = s.replace(/[^a-zA-Z0-9.!?]/g, "");
      return s;
    },

    // for the single input to show under the specific input error
    removeCharSpecial(s, fieldName) {
      // console.log('FIELD NAME____' , fieldName)
      let charAccents = {
        à: "a",
        À: "A",
        á: "a",
        Á: "A",
        â: "a",
        Â: "A",
        ä: "a",
        Ä: "A",
        è: "e",
        È: "E",
        é: "e",
        É: "E",
        ê: "e",
        Ê: "E",
        ë: "e",
        Ë: "E",
        í: "i",
        Í: "I",
        ì: "i",
        Ì: "I",
        î: "i",
        Î: "I",
        ï: "i",
        Ï: "I",
        ò: "o",
        Ò: "O",
        ó: "o",
        Ó: "O",
        ô: "o",
        Ô: "O",
        ö: "o",
        Ö: "O",
        ù: "u",
        Ù: "U",
        ú: "u",
        Ú: "U",
        û: "u",
        Û: "U",
        ü: "u",
        Ü: "U",
        ý: "y",
        Ý: "Y",
        ž: "z",
        Ž: "Z",
        ß: "ss",
        Ç: "C",
        č: "c",
        Č: "C",
        ď: "d",
        Ď: "D",
        ĺ: "l",
        Ĺ: "L",
        ľ: "l",
        Ľ: "L",
        ň: "n",
        Ň: "N",
        š: "s",
        Š: "S",
        ť: "t",
        Ť: "T",
        ŕ: "r",
        Ŕ: "R",
        "!": "",
        "?": "",
        ".": "",
      };

      // replace all characters not allowed
      s = s.replace(
        /[àÀáÁâÂäÄèÈéÉêÊëËíÍìÌîÎïÏòÒóÓôÔöÖùÙúÚûÛüÜýÝžŽßÇčČďĎĺĹľĽňŇšŠťŤŕŔ.!?]/g,
        (match) => charAccents[match] || match
      );

      // Rimuovi tutte le emoji
      s = s.replace(
        /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F1E6}-\u{1F1FF}]|[\u{200D}\u{200B}]/gu,
        ""
      );

      let cleanedString = s.replace(/[^a-zA-Z0-9.!?]/g, "");

      if (s !== cleanedString && this.specialCharMessages[fieldName]) {
        this.specialCharMessages[fieldName] =
          this.translations?.registration?.error_unexpected_special_char ??
          "Special characters don't allowed. All special characters will be replaced with their normal variant. Example: (à -> a).";

        // remove the timer if exists
        if (this.specialCharTimeout) {
          clearTimeout(this.specialCharTimeout);
        }

        // restart the timer
        this.specialCharTimeout = setTimeout(() => {
          this.specialCharMessages[fieldName] = "";
          this.specialCharTimeout = null;
        }, 3500);
      }

      // console.log('rimosso' , cleanedString)

      return cleanedString;
    },

    async saveRegistration() {
      if (this.isRegistrationSaving) {
        return;
      }

      this.isRegistrationSaving = true;

      this.verificationCodePhone = "";
      this.verificationCodeMail = "";
      const playerId = this.verificationPlayerID;
      let watch = ["firstname", "lastname", "dob"];
      let limits = {};
      let aml_score = 0;
      let manual_score = {};
      let status = "active";
      if (this.isPEP || this.isUBO) {
        limits = {
          max_daily_deposit: 100,
        }; //limit 100 if pep or ubo
        manual_score = [
          {
            points: 36,
            type: "aml_score",
            note: "Player is a PEP or UBO",
          },
        ];

        aml_score = 36; //red aml score
      }

      this.firstName = this.removeCharSpecial(this.firstName, "firstName");
      this.lastName = this.removeCharSpecial(this.lastName, "lastName");

      const body = {
        name: this.firstName + " " + this.lastName,
        firstname: this.firstName,
        lastname: this.lastName,
        gender: this.gender,
        street: this.street,
        streetNumber: this.streetNumber,
        address_line_1: this.street,
        address_line_2: this.streetNumber,
        city: this.city,
        postal_code: this.postalCode,
        is_pep: this.isPEP,
        status: status,
        is_ubo: this.isUBO,
        birth_country: this.birth_country,
        nationality: this.birth_country,
        referral_code: this.referralCode,
        newsletter: this.isNewsletterChecked,
        terms_and_conditions: true,
        check_mobile: true,
        dob: `${this.dob_Y}-${this.dob_M}-${this.dob_D}`,
        limits: limits,
        watch: watch,
        aml_score: aml_score,
        trigger_events: 1,
        manual_score: manual_score,
      };

      //First login notify
      let subjectNotify = this.translations.registration.protection;
      let bodyNotify = this.translations.registration.set_limits;
      let type = "firstLogin";
      let id = playerId;
      this.$store.dispatch("skinSettings/sendNotification", {
        subjectNotify,
        bodyNotify,
        type,
        id,
      });

      try {
        await this.$store.dispatch("registration/saveRegistration", {
          body,
          playerId,
        });

        this.$router.push(
          `/${localStorage.getItem("language")}/login?success=thankyou`
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isRegistrationSaving = false;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.changeBg);
  },
  created() {
    let language = localStorage.getItem("language");
    this.token = this.$route.params.temp_token;

    setInterval(() => {
      this.preventCopy();
    }, 1);
    this.messageCopy =
      this.translations?.body?.copy_cut_dont_allow ??
      "The copy and paste are not allowed in this field.";

    if (this.token) this.currentStep = -1;

    this.$router.push({ params: { lang: language }, query: this.$route.query });
    this.$store.dispatch("skinSettings/callCountriesAll");
  },
  mounted() {
    document.title =
      this.translations?.body?.registrationPageTitle ??
      "Kajot Online Casino | Registration";
    this.changeBg();
    this.$store.commit("skinSettings/createDates");
    this.getAndStoreReferralCode();
    window.addEventListener("resize", this.changeBg);
  },
};
</script>

<style scoped>
.form-img-campaign {
  position: relative;
}
.form-img-campaign .overlayImg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: rgba(33, 37, 41, 0.6);
}

.overlayImgCode {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 5;
  background: rgba(33, 37, 41, 0.6);
}

.form-img-campaign .overlayImg b {
  color: #fff;
}

.warning-text-for-czk {
  margin: 5px 0px;
}

.alert-czk {
  line-height: 1.75;
  color: var(--dark-orange);
  font-weight: bold;
  font-size: 12px;
}

.checkMobile-button {
  background-color: var(--light-orange);
  transition: var(--short-trans);
  border: 2px solid var(--light-orange);
  color: white;
  margin: 10px 0px 0px 0px;
}

.checkMobile-button:hover {
  background-color: var(--dark-orange);
  transition: var(--short-trans);
  border-color: var(--light-orange);
}
.success-mobile-valid {
  color: green;
  font-size: 0.65rem;
  transition: 0.3s all ease;
}

.loader-response-container {
  overflow: hidden;
  min-height: 80px;
}

.loader-response {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 6px solid var(--dark-orange);
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}
@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.error-field-empty {
  box-shadow: 0px 0px 7px 1px rgba(255, 0, 0, 0.721) !important;
  color: red !important;
  transition: 0.25s all ease !important;
}

.img-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.change-bg-btn {
  position: relative;
  top: -10px;
}

.btn-verified-code {
  background: var(--dark-orange);
  color: white !important;
  font-weight: 600;
  transition: 0.3s all ease;
  outline: none;
  border: none;
}
.btn-verified-code:hover {
  opacity: 0.9;
}
.verified-code {
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
}

.info-message-pn {
  font-size: 80%;
  font-weight: 600;
  letter-spacing: -0.04em;
  line-height: 1.25;
  background: #80808026;
  padding: 4px;
  border-radius: 5px;
}
</style>

<!-- <div class="form-group">
                <input
                  type="text"
                  class="form-control my-3"
                  :placeholder="translations.registration.first_name + '*'"
                  name="firstName"
                  id="firstName"
                  v-model.trim="firstName"
                  @keyup="checkFields"
                />
                <transition name="error-message">
                  <p v-if="isFieldsInvalid" class="warning my-2">
                    {{ fieldsErrorMessage }}
                  </p>
                </transition>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control my-3"
                  :placeholder="translations.registration.last_name + '*'"
                  name="lastName"
                  id="lastName"
                  v-model.trim="lastName"
                  @keyup="checkFields"
                />
                <transition name="error-message">
                  <p v-if="isFieldsInvalid" class="warning my-2">
                    {{ fieldsErrorMessage }}
                  </p>
                </transition>
              </div> -->
