<template>
  <div>
    <section>
      <img class="img-bg" :src="changeBg()" />
      <base-info-page
        style="
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          padding-top: 5rem !important;
        "
      >
        <template #title>{{ translations.login.login ?? "Login" }}</template>
        <template #default>
          <form @submit.prevent>
            <div class="form-group">
              <input
                v-model="nickname"
                type="text"
                id="nickname"
                :placeholder="translations.login.username ?? 'Username'"
                class="form-control my-3"
              />
            </div>
            <div class="form-group">
              <input
                v-model="password"
                type="password"
                id="password"
                :placeholder="translations.login.password ?? 'Password'"
                class="form-control my-3"
              />
            </div>
            <section>
              <div class="form-group">
                <base-button
                  @click="loginPlayer"
                  color="grey"
                  :caption="translations.login.login ?? 'Login'"
                  id="login"
                ></base-button>
              </div>

              <div class="form-group mt-3 text-center">
                <router-link :to="`/${language_selected}/recovery-password`">{{
                  translations.login.forgotten_password ?? "Forgot password?"
                }}</router-link>
              </div>
              <div class="form-group mt-5 text-center">
                <p class="text-center small">
                  {{ translations.login.new_player ?? "New player" }}
                </p>
                <base-button
                  @click="redirectToRegistration"
                  color="orange"
                  :caption="translations.login.register_now ?? 'Register now!'"
                  id="register"
                ></base-button>
              </div>
            </section>
          </form>
        </template>
      </base-info-page>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      bgForm: "../assets/img/rMob.jpg",
      opacity: 30,
      language_selected: localStorage.getItem("language"),
      nickname: "",
      password: "",
      isLoginButtonDisabled: true,
      ipAddress: {
        ip: null,
        country: null,
      },
    };
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    endpoint() {
      return this.$store.getters["api/endpoint"];
    },
    axios() {
      return this.$store.getters["api/axios"];
    },
    auth() {
      return this.$store.getters["api/auth"];
    },
    prefilled_nickname() {
      return this.$store.getters.prefilled_nickname;
    },
    prefilled_password() {
      return this.$store.getters.prefilled_password;
    },
    ...mapGetters("player", ["token"]),
  },
  methods: {
    changeBg() {
      const width = window.innerWidth;
      const isMobile = width <= 567;
      const opacity100 = this.opacity == 100;

      if (isMobile) {
        this.bgForm = opacity100 ? "../../images/rMob.jpg" : "../../images/rMob30.jpg";
      } else {
        this.bgForm = opacity100 ? "../../images/rDesk.jpg" : "../../images/rDesk30.jpg";
      }
      return `${this.bgForm}`;
    },
    changeOpacity(value) {
      if (value !== this.opacity) this.opacity = value;

      const opacity100 = document.getElementById("opacity100");
      const opacity30 = document.getElementById("opacity30");

      if (this.opacity == 100) {
        opacity100.checked = true;
        opacity30.checked = false;
      } else {
        opacity100.checked = false;
        opacity30.checked = true;
      }
    },
    //Add method to put /:lang on url
    redirectToRegistration() {
      this.$router.push({
        path: `/${this.language_selected}/registration`,
      });
    },
    callPrefilledData() {
      if (this.prefilled_nickname != null) {
        this.nickname = this.prefilled_nickname;
        this.password = this.prefilled_password;
        const prefilledData = {
          nickname: null,
          password: null,
        };
        this.$store.dispatch("setPrefilledLoginValue", prefilledData);
      } else {
        return false;
      }
    },
    getIpAddressData() {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((response) => {
          let ip = response.ip;
          let ipapi = "https://ipapi.co/" + ip + "/json/";
          this.axios
            .get(ipapi)
            .then((res) => {
              if (res.data.error)
                console.log("There is an error fetching ipdata.", res.data.error);
              else {
                this.ipAddress.ip = res.data.ip;
                this.ipAddress.country = res.data.country;
              }
            })
            .catch((err) => {
              console.log("There is an error fetching ipdata.", err);
              return false;
            });
        })
        .catch((err) => {
          console.log("Error IPDATA FETCH", err);
        });
    },
    async loginPlayer() {
      let cookName = "loginAttempts";
      let cookRec = "statusRecovery";
      let getCook = VueCookies.get(cookName) ?? 0;
      let recovPassw = VueCookies.get(cookRec) ?? null;
      if (getCook > 0) VueCookies.set(cookName, parseInt(getCook) + 1, 60 * 10);
      else VueCookies.set(cookName, 1, 60 * 10);

      getCook = VueCookies.get(cookName) ?? 0;

      let nickname = this.nickname;

      if (parseInt(getCook) >= 5) {
        // che corrisponde a dopo 5 tentativi
        if (recovPassw == "sendRecovery" && parseInt(getCook) == 5) {
          // vuol dire che il count è ripartito
          VueCookies.set(cookRec, "pendingBan", 3600); // 1 hour
          this.$store.dispatch("registration/recoverPassword", nickname);
        } else {
          if (recovPassw == "pendingBan" && parseInt(getCook) == 5) {
            this.$store.dispatch("registration/manyAttempts", nickname);
            VueCookies.remove(cookRec);
            return false;
          }
          Swal.fire({
            title: this.translations.dashboard_menu.Attention ?? "Attention",
            html:
              this.translations.login.attempts ??
              "You have made too many login attempts. Please try again in 10 minutes",
            footer:
              '<a href="/' +
              this.language_selected +
              '/recovery-password" class="w-100" style="text-align:left;">' +
              this.translations.login.recoverYourPassword +
              '</a><a class="w-100" style="text-align:right;" href="/contact">' +
              this.translations.header.support +
              "</a>",
            icon: "error",
          });
          if (!recovPassw) VueCookies.set(cookRec, "sendRecovery", 3600); //1 hour
        }
        return false;
      }

      this.$store.dispatch("player/login", {
        nickname: this.nickname,
        password: this.password,
        ipAddress: this.ipAddress,
      });
    },
    async logoutPlayer() {
      this.$store.dispatch("player/logout");
    },
  },
  created() {
  let language = localStorage.getItem("language");
  this.$router.push({
    params: { 
      ...this.$route.params,  
      lang: language  
    },
    query: this.$route.query  
  });
  },
  mounted() {
    document.title =
      this.translations?.body?.loginPageTitle ?? "Kajot Online Casino | Login";
    this.callPrefilledData();
    this.getIpAddressData();
    this.changeBg();
    window.addEventListener("resize", this.changeBg);
    if(this.$route.query.success === "thankyou") 
    {
      const script = document.createElement("script");
      script.innerHTML = `fbq('track', 'CompleteRegistration');`;
      document.body.appendChild(script);
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.changeBg);
  },
};
</script>

<style scoped>
.img-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.change-bg-btn {
  position: relative;
  top: -10px;
}
</style>
