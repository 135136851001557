<template>
  <section>
    <base-loading v-if="isTranslationLoading && !isMethodLoaded"></base-loading>
    <base-dashboard-section v-else>
      <template #nameSection>{{ translations.buttons.deposit }}</template>
      <template #default>
        <p
          class="text-center m-3"
          style="line-height: 1.2rem; font-weight: 600"
        >
          {{ translations.myprofile.custom_message_deposit ?? "" }}
        </p>
        <p class="text-center mx-3" v-if="playerInfo?.currency?.code === 'CZK'">
          <small
            style="
              line-height: 1.2rem;
              font-weight: 600;
              color: var(--dark-orange);
            "
          >
            {{
              translations?.payment_methods?.help_neterapay ??
              "In order to use NeteraPay payment services, you must first upload your ID card (back and front) and input the details as necessary."
            }}
          </small>
        </p>
        <div v-if="pendingTransactions">
          <div class="container mb-5">
            <div class="row justify-content-center">
              <div class="col-12 my-5 text-error text-center">
                {{
                  translations.account_balance.There_is_a_pending_transaction
                }}
              </div>
            </div>
            <div
              v-for="pendingTransaction in pendingTransactions"
              :key="pendingTransaction.id"
              class="row justify-content-center"
            >
              <div class="col-11 col-md-8">
                <div
                  class="row mb-5 justify-content-around align-items-center pending-section"
                >
                  <div class="col-11 col-md-4">
                    <div class="row my-2">
                      <div class="text-center">
                        <b>ID:</b> {{ pendingTransaction.id }}
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="text-center">
                        <b>{{ translations.reports.Status }}:</b>
                        {{ translations.account_balance.Pending }}
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="text-center">
                        <b>{{ translations.reports.Type }}:</b>
                        {{ translations.account_balance.Deposit }}
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="text-center">
                        <b>{{ translations.account_balance.Amount }}:</b>
                        {{ pendingTransaction.currency.symbol
                        }}{{ +pendingTransaction.amount }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      pendingTransaction.payload.hasOwnProperty(
                        '/transaction/info'
                      ) &&
                      pendingTransaction.payload[
                        '/transaction/info'
                      ].hasOwnProperty('resultData') &&
                      pendingTransaction.payload[
                        '/transaction/info'
                      ].resultData.hasOwnProperty('details') &&
                      pendingTransaction.payload['/transaction/info'].resultData
                        .details.length != 0
                    "
                    class="col-11 col-md-8"
                  >
                    <div class="row my-1">
                      <div class="text-center">
                        <b>Bank account number:</b>
                        {{
                          pendingTransaction.payload["/transaction/info"]
                            .resultData.details.bban +
                            "/" +
                            pendingTransaction.payload["/transaction/info"]
                              .resultData.details.bank_code ?? ""
                        }}
                      </div>
                    </div>
                    <div class="row my-1">
                      <div class="text-center">
                        <b>Variable symbol:</b>
                        {{
                          pendingTransaction.payload["/transaction/info"]
                            .resultData.details.variable_symbol ?? ""
                        }}
                      </div>
                    </div>
                    <div class="row my-1">
                      <div class="text-center">
                        <b>Owner:</b>
                        {{
                          pendingTransaction.payload["/transaction/info"]
                            .resultData.details.holder ?? ""
                        }}
                      </div>
                    </div>
                    <div class="row my-1">
                      <div class="text-center">
                        <b>IBAN:</b>
                        {{
                          pendingTransaction.payload["/transaction/info"]
                            .resultData.details.iban ?? ""
                        }}
                      </div>
                    </div>
                    <div class="row my-1">
                      <div class="text-center">
                        <b>BIC/SWIFT:</b>
                        {{
                          pendingTransaction.payload["/transaction/info"]
                            .resultData.details.bic ?? ""
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <transition name="form">
          <div
            class="container"
            v-if="!showMessage && !isSelfExclusion && !pendingTransactions"
          >
            <div
              v-if="playerInfo.marketing.terms_and_conditions"
              class="row d-flex justify-content-center"
            >
              <form @submit.prevent>
                <div class="form-group row justify-content-center mt-4 mb-5">
                  <div class="col-12 col-md-6 my-3">
                    <label class="form-label" for="amount">{{
                      translations.account_balance.Amount
                    }}</label>
                    <input
                      id="amount"
                      type="number"
                      class="form-control"
                      v-model="amount"
                      :placeholder="translations.account_balance.Insert_amount"
                    />
                    <transition name="error-message">
                      <p v-if="errorAmount" class="mt-2 warning">
                        {{ errorMessage }}
                      </p>
                    </transition>
                  </div>
                  <div class="col-12 col-md-6 my-3">
                    <label class="form-label">{{
                      translations.payment_methods.payment_methods
                    }}</label>
                    <select
                      class="form-control"
                      name="account_id"
                      id="account_id"
                      v-model="account_id"
                    >
                      <option value="null" disabled selected>
                        {{ translations.payment_methods.deposit_methods }}
                      </option>
                      <option
                        v-for="method in depositPaymentMethodsFiltered"
                        :key="method.id"
                        :value="method.id"
                      >
                        {{ formatMethodName(method.name) }}
                      </option>
                    </select>
                  </div>
                  <transition name="fade">
                    <div v-if="account_id != null" class="col-12 my-3">
                      <div class="row mb-3">
                        <div v-if="showConnect" class="col-12">
                          <p style="font-weight: 600">
                            {{
                              translations.payment_methods.neterepay_help_1 ??
                              "To use this payment method, you must"
                            }}
                            <button
                              type="button"
                              @click="makeDeposit(0)"
                              :disabled="isButtonClicked"
                              class="link-orange"
                            >
                              {{
                                translations.payment_methods.neterepay_help_2 ??
                                "connect your NeteraPay wallet"
                              }}
                            </button>
                            {{
                              translations.payment_methods.neterepay_help_3 ??
                              "to your player account."
                            }}
                          </p>
                        </div>
                        <div v-else-if="showIdentify" class="col-12">
                          <p style="font-weight: 600">
                            {{
                              translations.payment_methods.neterepay_help_4 ??
                              "To use this payment method, you must have a NeteraPay e-wallet. You must perform an"
                            }}
                            <button
                              type="button"
                              @click="makeDeposit(0)"
                              :disabled="isButtonClicked"
                              class="link-orange"
                            >
                              {{
                                translations.payment_methods.neterepay_help_5 ??
                                "online verification to create a wallet."
                              }}
                            </button>
                          </p>
                        </div>
                      </div>
                      <div
                        class="row justify-content-around align-items-center limits-suggest"
                      >
                        <div class="col-12 col-md-2">
                          <div class="text-center">
                            <i>{{ formatMethodName(methodName) }}</i>
                          </div>
                        </div>
                        <div class="col-12 col-md-4">
                          <div class="text-center">
                            <b
                              >{{ translations.account_balance.Min }}
                              {{ translations.account_balance.Deposit }}:</b
                            >
                            {{ playerInfo?.currency?.symbol ?? "" }}
                            {{ minDepositable }}
                          </div>
                        </div>
                        <div class="col-6 col-md-4">
                          <div class="text-center">
                            <b
                              >{{ translations.account_balance.Max }}
                              {{ translations.account_balance.Deposit }}:</b
                            >
                            {{ playerInfo?.currency?.symbol ?? "" }}
                            {{ maxDepositable }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                  <div class="col-12 my-3">
                    <label class="form-label" for="bonus_code">{{
                      translations.account_balance.Bonus_Code
                    }}</label>
                    <input
                      id="bonus_code"
                      type="text"
                      class="form-control"
                      v-model="bonus_code"
                    />
                    <small>{{
                      translations.account_balance.bonus_code_info ??
                      'If a promotion is already active in your profile, the "bonus code" will not be taken into consideration'
                    }}</small>
                  </div>
                </div>
                <div class="form-group row mt-4 mb-5">
                  <base-button
                    @click="makeDeposit"
                    color="orange"
                    :caption="depositCaption"
                    :disabled="isDepositButtonDisabled"
                  ></base-button>
                </div>
              </form>
            </div>
            <div v-else v-html="translations.body.terms_and_conditions"></div>
          </div>
        </transition>
        <transition name="form">
          <div class="container" v-if="showMessage && !isSelfExclusion">
            <transition name="form">
              <div class="error-message" v-if="!!depositErrorMessage1">
                {{ depositErrorMessage1 }}
              </div>
            </transition>
            <transition name="form">
              <div class="error-message" v-if="!!depositErrorMessage2">
                {{ depositErrorMessage2 }}
              </div>
            </transition>
            <transition name="form">
              <div class="row mb-5" id="formInFrame" target="_PARENT">
                <iframe
                  class="responsive-iframe"
                  :srcdoc="depositForm"
                ></iframe>
              </div>
            </transition>
            <div v-if="(showCancelButton = true)">
              <button
                class="cancelDepositButton"
                @click="comeBackToDepositPage"
              >
                {{ translations.buttons.cancelDepositButton }}
              </button>
            </div>
          </div>
        </transition>
        <transition name="form">
          <div class="container" v-if="isSelfExclusion">
            <transition name="form">
              <div class="error-message">
                {{
                  translations.dashboard_menu.excludeMessage ??
                  "You have decided to exclude yourself. Contact customer support to reactivate your gaming account."
                }}
              </div>
            </transition>
          </div>
        </transition>
      </template>
    </base-dashboard-section>
  </section>
</template>

<style scoped>
#formInFrame {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
<script>
import avoidAnotherClick from "@/mixins/avoidDoubleClick.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      amount: null,
      isAmountSet: false,
      account_id: null,
      isMethodSet: false,
      bonus_code: "",
      isSelfExclusion: false,
      showMessage: false,
      depositForm: null,
      depositErrorMessage1: null,
      depositErrorMessage2: null,
      showCancelButton: false,
      isMethodLoaded: false,
      showConnect: false,
      showIdentify: false,
    };
  },
  mixins: [avoidAnotherClick],
  async created() {
    this.$store.dispatch("payments/callPaymentMethods").then(() => {
      this.$store
        .dispatch("payments/getDepositPaymentMethods")
        .then(() => {
          this.isMethodLoaded = true;
        })
        .catch((error) => {
          console.error("Error during getDepositPaymentMethods:", error);
        });
    });

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("code")) this.bonus_code = urlParams.get("code");

    if (this.playerInfo.status_expires_at != null) {
      var varDate = new Date(this.playerInfo.status_expires_at); //dd-mm-YYYY
      if (varDate > new Date()) this.isSelfExclusion = true;
    }
    this.checkPendingDeposit();
  },
  computed: {
    methodName() {
      const name = () => {
        let selected = this.account_id;
        let selectedMethod = this.depositPaymentMethods?.find(
          (method) => method.id == selected
        );
        if (selected != null) {
          return selectedMethod.name;
        }
      };
      return name();
    },
    ...mapGetters("payments", ["depositPaymentMethodsFiltered"]),
    neterapayPaymentMethods() {
      let methods = this.depositPaymentMethodsFiltered.filter((method) =>
        ["external", "wire_transfer", "gp_webpay", "mbankomat"].includes(
          method.code
        )
      );

      return methods.length > 0 ? methods.map((el) => el.id) : methods;
    },
    maxDepositable() {
      const maximum = () => {
        let selected = this.account_id;
        let selectedMethod = this.depositPaymentMethods?.find(
          (method) => method.id == selected
        );
        if (selected != null) {
          return selectedMethod.data.maximum;
        }
      };
      return maximum();
    },
    pendingTransactions() {
      return this.pendingDeposit && this.pendingDeposit.length != 0
        ? this.pendingDeposit
        : null;
    },
    minDepositable() {
      const minimum = () => {
        let selected = this.account_id;
        let selectedMethod = this.depositPaymentMethods?.find(
          (method) => method.id == selected
        );
        if (selected != null) {
          return selectedMethod.data.minimum;
        }
      };
      return minimum();
    },
    errorAmount() {
      if (this.amount > this.maxDepositable) {
        return true;
      } else if (this.amount <= this.maxDepositable) {
        return false;
      } else {
        return false;
      }
    },
    errorMessage() {
      return (
        this.translations.account_balance.Amount_too_high +
        " " +
        this.playerInfo?.currency?.symbol +
        this.maxDepositable
      );
    },
    isDepositButtonDisabled() {
      return (
        !this.isAmountSet ||
        !this.isMethodSet ||
        this.errorAmount ||
        this.isButtonClicked ||
        this.showConnect ||
        this.showIdentify
      );
    },
    depositCaption() {
      if (this.isButtonClicked) {
        return "...";
      } else {
        return this.translations.buttons.deposit.toUpperCase();
      }
    },
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("reports", ["isCheckingPendingDeposit", "pendingDeposit"]),
    ...mapGetters("payments", [
      "depositResponse",
      "depositPaymentMethods",
      "npIsPinRegisteredResponse",
    ]),
    ...mapGetters("player", ["playerInfo", "balance"]),
  },
  watch: {
    amount(value) {
      if (value >= 1) {
        this.isAmountSet = true;
      } else {
        this.isAmountSet = false;
      }
    },
    account_id(newValue, oldValue) {
      if (newValue != null) {
        this.isMethodSet = true;
      } else {
        this.isMethodSet = false;
      }

      let response = this.npIsPinRegisteredResponse;
      let condition =
        (!response ||
          (response &&
            Object.prototype.hasOwnProperty.call(response, "status") &&
            response.status == "failed")) &&
        this.neterapayPaymentMethods.includes(newValue);
      if (condition)
        /* Only the first time a neterapay payment method is clicked, call is pin registered to check if the player needs to link his neterapay wallet or not */
        this.$store.dispatch("payments/callNeterapayIsPinRegistered");

      if (!this.neterapayPaymentMethods.includes(newValue)) {
        /* When anothr payment method is selected, hide neterapay's messages */
        this.showConnect = false;
        this.showIdentify = false;
      } else if (
        response &&
        this.neterapayPaymentMethods.includes(newValue) &&
        !this.neterapayPaymentMethods.includes(oldValue)
      ) {
        /* When the player selects a neterapay's payment method, check for the response to understand whether to show the messages or not */
        if (
          Object.prototype.hasOwnProperty.call(response, "identify") &&
          response.identify
        )
          this.showIdentify = true;
        else if (
          Object.prototype.hasOwnProperty.call(response, "connect") &&
          response.connect
        )
          this.showConnect = true;
      }
    },
    npIsPinRegisteredResponse(value) {
      if (
        Object.prototype.hasOwnProperty.call(value, "is_neterapay_verified") &&
        !value.is_neterapay_verified
      ) {
        if (
          Object.prototype.hasOwnProperty.call(value, "connect") &&
          value.connect == true
        )
          this.showConnect = true;
        else if (
          Object.prototype.hasOwnProperty.call(value, "identify") &&
          value.identify == true
        )
          this.showIdentify = true;
      }
    },
    depositResponse(value) {
      if (Object.prototype.hasOwnProperty.call(value, "message")) {
        this.depositErrorMessage1 = value.message;

        let msgReplaced = this.removeSpaceForTranslations(
          this.depositErrorMessage1
        );

        if (this.translations?.dashboard_menu?.[msgReplaced]) {
          this.depositErrorMessage1 =
            this.translations?.dashboard_menu?.[msgReplaced];
        }

        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
        }, 4000);
      } else if (Object.prototype.hasOwnProperty.call(value, "amount")) {
        let amount = value.amount[0];
        let message = amount.charAt(0).toUpperCase() + amount.slice(1);
        this.depositErrorMessage2 = message;
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
        }, 4000);
      } else {
        //qui devo fare il bottone visibie
        this.showCancelButton = true;
        if (value.includes("<form")) {
          //this.depositForm = value;
          document.body.innerHTML = value;
          let apco = document.getElementById("submitapco");
          if (apco)
            setTimeout(function () {
              apco.submit();
            }, 2000);
        } else {
          let startFromSrc = value.slice(value.search("src")).slice(5);
          let src = startFromSrc.slice(0, startFromSrc.search('"'));
          window.open(src, "_blank");
        }
        this.showMessage = true;
      }
    },
    // depositForm(value) {
    // 	if (value != null) {
    // 		let frame = window.open('localhost:8080', 'deposit','_blank');
    // 		frame.document.write(`<html>${value}</html>`);
    // 		let script = frame.document.createElement('script'),
    // 			s;
    // 		script.setAttribute('type', 'text/javascript');
    // 		frame.document.getElementsByTagName('head')[0].appendChild(script);
    // 		let foo = `window.onclick = function () {
    // 			const mod = document.querySelector('form')
    // 			mod.addEventListener('submit', ()=> {window.close()})
    // 		}`;
    // 		s = foo;
    // 		script.innerHTML = s;
    // 		location.reload();
    // 	}
    // },
  },
  methods: {
    formatMethodName(method) {
      if (method === "Interac") {
        return "Interac ®";
      }
      return method;
    },
    removeSpaceForTranslations(message) {
      // Rimuove gli spazi e rende il messaggio tutto in lowercase
      return message.replace(/\s+/g, "").toLowerCase();
    },
    /* Added the possibility to force the deposit amount,
      currently used with neterapay to link the player's np wallet
      to his profile by making a sort of penny drop verification  */
    makeDeposit(force_amount = null) {
      let fullUrl = location.protocol + "//" + location.host;
      let isAmountForced = typeof force_amount === "number";
      if ((this.showConnect || this.showIdentify) && !isAmountForced) return; // The button is disabled, but if someone thinks he's smarter, he won't be able to make the call annyway
      this.avoidAnotherClick();
      this.depositForm = null;
      this.depositErrorMessage1 = null;
      this.depositErrorMessage2 = null;
      let body = {
        amount: +(isAmountForced ? force_amount : this.amount),
        payment_method_id: this.account_id,
        bonus_code: this.bonus_code,
        createLevel: "BasicAmlLevel",
        cancel_url: "/deposit",
        redirect_success: fullUrl + "/transactions/{transaction_id}",
      };
      if (isAmountForced && (this.showConnect || this.showIdentify)) {
        body.skip_amount_limits = true;
        body.neterapay_endpoint = this.showConnect ? "connect" : "identify";
        body.redirect_url = window.location.href;
      }
      this.$store.dispatch("payments/makeDeposit", body);
    },
    comeBackToDepositPage() {
      location.reload();
    },
    checkPendingDeposit() {
      let start = new Date("1970-01-01").toISOString().slice(0, 10);
      let end = new Date().toISOString().slice(0, 10);
      const from = "?date_from=" + start + " 00:00:00";
      const to = "&date_to=" + end + " 23:59:59";
      let type = "&type_id=1";
      let status = "&status=pending";
      let path = from + to + type + status;
      this.$store.dispatch("reports/checkPendingDeposit", path);
    },
  },
  mounted() {
    // const lang = localStorage.getItem("language") || "en";
    // const titles = {
    //   en: "Kajot Online Casino | Withdrawal",
    //   sk: "Kajot Online Casino | Vyplatiť",
    //   cs: "Kajot Online Casino | Vyplatit",
    //   de: "Kajot Online Casino | Auszahlen",
    //   hu: "Kajot Online Casino | Visszavonás",
    // };
    // document.title = titles[lang] || "Kajot Online Casino | Withdrawal";
    document.title =
      this.translations?.body?.depositPageTitle ??
      "Kajot Online Casino | Deposit";
  },
  unmounted() {
    this.showCancelButton = false;
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.error-message {
  text-align: center;
  color: var(--dark-red);
  font-weight: 700;
}
@media (min-width: 1px) {
  #formInFrame >>> .col-md-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
#formInFrame >>> button[type="submit"] {
  width: 100%;
}
.limits-suggest {
  padding: 15px 5px;
  box-shadow: var(--light-shadow);
  border: 1px solid var(--light-orange);
  border-radius: 10px;
}

.cancelDepositButton {
  /* border-radius: 10px;
	background-color: red;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); */
  border-radius: 5px;
  cursor: pointer;
  display: block;
  border: none;
  font-weight: 400;
  outline: 0;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color 0.2s ease, -webkit-box-shadow 0.2s ease;
  -o-transition: background-color 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease,
    -webkit-box-shadow 0.2s ease;
  background-color: rgb(219, 81, 18);
  font-weight: bolder;
  color: white;
  margin: 24px auto;
  max-width: 70%;
}

.pending-section {
  border: 2px solid var(--light-grey);
  box-shadow: var(--light-shadow);
  padding: 10px 20px;
  border-radius: 10px;
}

.link-orange {
  color: var(--light-orange);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  transition: 0.3s all;
  border: none;
  outline: none;
  background-color: transparent;
}

.link-orange:disabled,
.link-orange.disabled {
  cursor: not-allowed;
  color: inherit;
}
</style>
